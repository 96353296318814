import * as Sentry from "@sentry/nuxt";

if (import.meta.env.PROD) {
    Sentry.init({
        // If set up, you can use your runtime config here
        // dsn: useRuntimeConfig().public.sentry.dsn,
        dsn: "https://359f6b5fd410d2087358a7ca27dc2a61@o4507564914704384.ingest.us.sentry.io/4508077144276992",
        integrations: [Sentry.replayIntegration()],
        // Tracing
        // We recommend adjusting this value in production, or using a tracesSampler for finer control.
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/www\.nearhub\.us/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
} else {
    console.log("In development mode, Sentry events will not be reported.");
}
